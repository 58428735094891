import { library, dom, config } from '@fortawesome/fontawesome-svg-core';

// Light icons
import { faSearch as faSearchLight,  faEye, faGlobe, faTimes as faTimesLight, faPlayCircle, faArrowUp, faEdit, faSpinner, faQuoteLeft, faQuoteRight, faCircle as faCircleLight, faUserRobot } from '@fortawesome/pro-light-svg-icons';

// Regular icons
import { faChevronRight as faChevronRightRegular, faChevronLeft as faChevronLeftRegular, faChevronUp as faChevronUpRegular, faSearch as faSearchRegular, faTimes as faTimesRegular, faTimesCircle, faDesktopAlt, faCommentsAlt, faWindow, faShieldAlt, faCalendarAlt, faChartBar, faCode, faFileImage, faTasks, faDownload, faObjectGroup } from '@fortawesome/pro-regular-svg-icons';

// Solid icons
import { faSearch as faSearchSolid, faLink, faCircle, faSpinner as faSpinnerSolid, faArrowAltDown, faArrowAltSquareDown, faChevronLeft, faChevronRight, faDatabase, faPhone, faChevronCircleRight, faChevronCircleLeft, faChevronCircleUp, faUserFriends, faCog, faCogs, faBriefcase, faQuestionCircle, faUserHeadset, faEnvelope, faStars, faUserRobot as faUserRobotSolid, faChevronUp as faChevronUpSolid } from '@fortawesome/pro-solid-svg-icons';

// Duotone icons
import { faChevronUp as faChevronUpDuo, faArrowUp as faArrowUpDuo } from '@fortawesome/pro-duotone-svg-icons';

// Brand icons
import { faFacebookF, faTwitter, faLinkedinIn, faInstagram, faApple } from '@fortawesome/free-brands-svg-icons';

config.searchPseudoElements = true;
config.keepOriginalSource = false;

library.add(
	faApple,
	faArrowUp,
	faArrowUpDuo,
	faArrowAltDown,
	faArrowAltSquareDown,
	faBriefcase,
	faCalendarAlt,
	faChartBar,
	faChevronCircleLeft,
	faChevronCircleRight,
	faChevronLeft,
	faChevronLeftRegular,
	faChevronUpDuo,
	faChevronUpRegular,
	faChevronRight,
	faChevronRightRegular,
	faChevronUpSolid,
	faCircle,
	faCircleLight,
	faChevronCircleUp,
	faCode,
	faCog,
	faCogs,
	faCommentsAlt,
	faDesktopAlt,
	faDatabase,
	faDownload,
	faEdit,
	faEnvelope,
	faEye,
	faFacebookF,
	faFileImage,
	faGlobe,
	faLinkedinIn,
	faInstagram,
	faLink,
	faObjectGroup,
	faPhone,
	faPlayCircle,
	faQuestionCircle,
	faQuoteLeft, 
	faQuoteRight,
	faSearchLight, 
	faSearchRegular,
	faSearchSolid,
	faShieldAlt,
	faSpinner,
	faSpinnerSolid,
	faStars,
	faTasks,
	faTimesCircle,
	faTimesLight,
	faTimesRegular,
	faTwitter,
	faUserFriends,
	faUserHeadset,
	faUserRobot,
	faUserRobotSolid,
	faWindow	
);
dom.i2svg();
dom.watch();